<template>
  <v-footer class="footer">
    <v-container>
      <a href="https://columbus.digital" class="site-logo__link">
        <img class="site-logo desktop" :src="footerLogoDesktop" loading="lazy" />
        <img class="site-logo mobile" :src="footerLogoMobile" loading="lazy" />
      </a>

      <div class="d-flex align-center footer-social">
        <div class="footer-pre-text">Say hello at <a href="mailto:hello@columbus.tech">hello@columbus.tech</a></div>
        <v-spacer></v-spacer>

        <a href="https://www.linkedin.com/company/columbusdigital/" target="_blank" class="social-link"><img :src="linkedinIcon" loading="lazy" /></a>
      </div>

      <div class="footer__border"></div>

      <div class="footer__list">
        <ul>
          <li class="list-title">Resources</li>
          <li>
            <a href="https://columbus.digital/resources">Articles</a>
          </li>
          <li>
            <a href="https://columbus.digital/resources">Events</a>
          </li>
        </ul>

        <ul>
          <li class="list-title">Company</li>
          <li>
            <a href="https://columbus.digital/about-us">About us</a>
          </li>
          <li>
            <a href="https://columbus.digital/about-us">The team</a>
          </li>
          <li>
            <a href="https://columbus.digital/about-us">Support and Contact</a>
          </li>
        </ul>
      </div>

      <div class="footer__border"></div>

      <div class="copyright">
        Copyright © 2024. Registration on or use of this site constitutes acceptance of our: <a href="https://columbus.digital/terms-and-conditions">Terms and Conditions</a> and <a href="https://columbus.digital/privacy-policy">Privacy Policy</a>
      </div>

    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "HeaderSite",
  data() {
    return {
      footerLogoDesktop: require("@/assets/img/footer-logo-desktop.png"),
      footerLogoMobile: require("@/assets/img/footer-logo-mobile.png"),
      linkedinIcon: require("@/assets/img/icons/linkedin.svg"),
    };
  },
};
</script>

<style lang="scss" scoped>
.footer {
  background: #FFF;
  color: #22262E;

  .site-logo {
    width: 580px;
    display: none;

    &__link {
      display: block;
      width: max-content;
      max-width: -webkit-fill-available;
      margin-bottom: 30px;
    }

    &.mobile {
      @media screen and (max-width: 768px) {
        display: block;
      }
    }

    &.desktop {
      @media screen and (min-width: 769px) {
        display: block;
      }
    }
  }

  &__list {
    display: flex;
    border-top: 1px solid #D2D7D9;
    border-bottom: 1px solid #D2D7D9;
    margin: 20px 0;
    padding: 20px 0;

    ul {
      list-style: none;
      margin-right: 56px;

      &:last-child {
        margin-right: 0;
      }

      a {
        color: #22262E;
        transition: 350ms;

        &:hover {
          color: #000000;
        }
      }

      li {
        margin-bottom: 8px;
      }

      .list-title {
        font-family: 'Recoleta';
        font-size: 32px;
        line-height: 1;
        margin-bottom: 15px;
      }
    }
  }

  .footer-pre-text {
    font-family: 'Recoleta';
    font-size: 30px;
    line-height: normal;
    font-weight: bold;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .copyright {
    font-size: 14px;
  }

  // mobile styles
  @media screen and (max-width: 768px) {
    .footer-social {
      flex-wrap: wrap;

      .v-spacer {
        display: none;
      }

      .footer-pre-text {
        font-size: 20px;
        margin-bottom: 10px;
      }
    }

    .social-link
    {
      width: 45px;
      height: 45px;
      margin-left: 0;
      margin-right: 10px;
    }

    .footer__list {
      flex-wrap: wrap;

      ul {
        width: 100%;
        margin-bottom: 15px;
      }
    }
  }
}
</style>
