<template>
  <div class="main-section help-page">
    <HeaderSite />
    <HelpCenterMenu/>
    <div class="page-main-content">
      <div v-if="showMenuTabContent === 'trainingModule'">
        <div class="page-banner bg-color-primary d-flex" id="trainingModule">
          <div class="page-banner__img-box">
            <img class="banner-img" :src="pageBanner" alt="banner"/>
          </div>
          <div class="page-banner__content d-flex w-100">
            <v-container fluid>
              <v-row class="h-100">
                <v-col cols="8" md="4" sm="6">
                  <div class="page-banner__content--inner d-flex flex-column justify-space-between h-100">
                    <LogoBox :color-light="true"/>
                    <h1 class="page-title font-600 my-4">OKR Feature - Training Module</h1>
                    <p class="copyright-text"><b class="font-600">by Columbus Digital</b> <br>2024</p>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </div>
      </div>

      <div v-if="showMenuTabContent === 'basicOfOKRs'">
        <section class="doc-intro-section doc-section bg-color-primary" id="basicOfOKRs">
          <v-container fluid>
            <LogoBox :color-light="true"/>
            <v-row class="align-center h-100">
              <v-col>
                <h2 class="doc-intro-title doc-section-title" data-count="0.1">Basics of OKRs</h2>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </div>

      <div v-if="showMenuTabContent === 'strategyPyramid'">
        <section class="doc-info-section doc-section bg-color-tertiary" id="strategyPyramid">
          <v-container fluid class="position-relative">
            <v-row>
              <v-col cols="12">
                <div class="doc-info__title-box">
                  <h3 class="doc-info-title">Strategy Pyramid</h3>
                </div>
              </v-col>
              <v-col cols="12" xxl="6" md="4" class="mb-6 mb-md-0">
                <ol class="strategy-list pr-2">
                  <li><p class="info">The <strong>Vision</strong> statement illustrates what the organisation ultimately wants to accomplish and provides a clear direction for its efforts</p></li>
                  <li><p class="info">The <strong>Mission</strong> statement articulates the organisation's fundamental reason for existence, outlining its core values, activities, and the impact it seeks to make. </p></li>
                  <li><p class="info"><strong>Strategic Objectives</strong> for the year provides a clear roadmap for achieving the organisation's long-term strategy and vision.</p></li>
                  <li><p class="info"><strong>Strategic Themes</strong> help in organizing and prioritizing the organisation's strategic objectives, promoting alignment, focus and effective communication throughout the organisation.</p></li>
                  <li><p class="info"><strong>OKRs</strong> is a structured framework that translates strategic themes and strategic objectives into actionable goals and outcomes.</p></li>
                </ol>
              </v-col>
              <v-col cols="12" md="8" class="align-self-end">
                <img class="strategy-pyramid-img" alt="strategy" :src="strategyPyramid" />
              </v-col>
            </v-row>
          </v-container>
        </section>
      </div>

      <div v-if="showMenuTabContent === 'whatAreOKRs'">
        <section class="doc-info-section doc-section bg-color-tertiary" id="whatAreOKRs">
          <v-container fluid>
            <v-row class="align-start align-md-center">
              <v-col cols="12" class="mx-auto">
                <div class="doc-info__title-box mb-8 mb-md-6">
                  <h3 class="doc-info-title">What are OKRs?</h3>
                  <p class="subtitle mt-2">OKRs, or Objectives and Key Results, are a goal-setting framework used by organisations to define and track objectives and their outcomes. This framework helps in setting clear, measurable goals and ensuring alignment across different levels of an organisation.</p>
                </div>
              </v-col>
            </v-row>
            <v-row class="info-cards justify-space-evenly">
              <v-col cols="12" md="4" sm="6">
                <div class="info-card">
                  <div class="info-card--inner">
                    <div class="card-header">
                      <h5 class="card-title with-icon font-600"><img class="icon" alt="objectives" :src="objectivesIcon" />Objectives</h5>
                    </div>
                    <div class="card-body">
                      <p>These are the specific, clearly defined goals that an organisation, team, or individual aims to achieve.</p>
                      <p>Objectives should be ambitious, inspiring, and qualitative.</p>
                      <p>They provide direction and set the context for what needs to be accomplished</p>
                    </div>
                  </div>
                  <div class="info-examples font-600 mt-3">
                    <p class="info-example"><span class="label">Example:&nbsp;</span><span class="example-description font-italic">deliver an exceptional customer experience to our in-store customers to ensure high customer satisfaction</span></p>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="4" sm="6">
                <div class="info-card">
                  <div class="info-card--inner">
                    <div class="card-header">
                      <h5 class="card-title with-icon font-600"><img class="icon" :src="keyResultsIcon" alt="key-result" />Key Results</h5>
                    </div>
                    <div class="card-body">
                      <p>These are the specific, measurable outcomes that indicate progress toward achieving the objective.</p>
                      <p>Key Results should be quantitative and time-bound, providing a clear way to track progress and determine if the objective has been met.</p>
                    </div>
                  </div>
                  <div class="info-examples font-600 mt-3">
                    <p><span class="label">Example:&nbsp;1&nbsp;</span><span class="example-description font-italic">Increase Net Promoter Score (NPS) to 50</span></p>
                    <p><span class="label">Example:&nbsp;2&nbsp;</span><span class="example-description font-italic">Reduce customer support response time from 8 hours to 2 hours</span></p>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </div>

      <div v-if="showMenuTabContent === 'whyDoWeNeedOKRs'">
        <section class="doc-info-section doc-section bg-color-tertiary" id="whyDoWeNeedOKRs">
          <v-container fluid>
            <v-row class="align-center">
              <v-col cols="12" class="mx-auto">
                <div class="doc-info__title-box mb-8 mb-md-6">
                  <h3 class="doc-info-title">Why do we need OKRs?</h3>
                  <p class="subtitle mt-2">OKRs, or Objectives and Key Results, are essential for organisations because they provide a structured framework for setting and achieving goals. </p>
                </div>
              </v-col>
              <v-col cols="12" md="10" class="mx-auto mb-auto">
                <ul class="info-list pl-4 pl-md-10 pl-sm-6">
                  <li class="mb-4"><span class="label font-weight-bold">Ensure Alignment:</span> Align everyone's efforts towards common goals, creating a unified direction.</li>
                  <li class="mb-4"><span class="label font-weight-bold">Provide Clarity:</span> Define clear, specific goals and measurable outcomes, making roles and expectations transparent.</li>
                  <li class="mb-4"><span class="label font-weight-bold">Enhance Focus:</span> Help prioritize tasks and allocate resources to the most important initiatives.</li>
                  <li class="mb-4"><span class="label font-weight-bold">Promote Accountability:</span> Establish measurable key results that hold teams and individuals responsible for their progress.</li>
                  <li class="mb-4"><span class="label font-weight-bold">Boost Motivation:</span> Set ambitious objectives that inspire and engage employees, fostering a sense of purpose.</li>
                  <li class="mb-4"><span class="label font-weight-bold">Enable Agility:</span> Allow for regular reviews and adjustments, helping organisations to monitor and navigate risk proactively.</li>
                  <li class="mb-4"><span class="label font-weight-bold">Improve Performance:</span> Link strategic planning with execution, empowering teams to become more outcome centric i.e., data driven.</li>
                </ul>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </div>

      <div v-if="showMenuTabContent === 'createYourOKRs'" >
        <section class="doc-intro-section doc-section bg-color-primary" id="createYourOKRs">
          <v-container fluid>
            <LogoBox :color-light="true"/>
            <v-row class="align-center h-100">
              <v-col>
                <h2 class="doc-intro-title doc-section-title" data-count="0.2">Create Your OKRs</h2>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </div>

      <div v-if="showMenuTabContent === 'createAnObjective'" class="doc-section--outer">
        <section class="doc-info-section doc-section bg-color-tertiary" id="createAnObjective">
          <v-container fluid>
            <v-row>
              <v-col cols="12" class="mx-auto">
                <div class="doc-info__title-box mb-8 mb-md-6">
                  <h3 class="doc-info-title">Create an Objective</h3>
                  <p class="subtitle mt-2">Objectives in the OKR (Objectives and Key Results) framework are <span class="info-primary">clear, ambitious, and qualitative</span> statements that define what an organisation, team, or individual aims to achieve within a specific timeframe.</p>
                </div>
              </v-col>
              <v-col cols="12" class="mx-auto">
                <v-row class="info-cards flex-column info-card-steps mx-0">
                  <v-col cols="12" md="4" sm="6" class="px-0">
                    <div class="info-card">
                      <div class="info-card--inner">
                        <div class="card-header">
                          <h5 class="card-title">Step 1</h5>
                        </div>
                        <div class="card-body">
                          <p>Click on Create OKR from the top navigation bar</p>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="4" sm="6" class="mx-auto px-0">
                    <div class="info-card info-card-center">
                      <div class="info-card--inner">
                        <div class="card-header">
                          <h5 class="card-title">Step 2</h5>
                        </div>
                        <div class="card-body">
                          <p>Complete all the mandatory fields</p>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="4" sm="6" class="ml-auto px-0">
                    <div class="info-card info-card-right">
                      <div class="info-card--inner">
                        <div class="card-header">
                          <h5 class="card-title">Step 3</h5>
                        </div>
                        <div class="card-body">
                          <p>Click on Save & Add Key Result (to add 1 or more Key Result for completing the OKRs) or Click on Save as Draft (to complete the OKR later)</p>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
          <button type="button" class="scroll-down-link" @click.stop="scrollToNextBlock">
            Scroll to watch this feature video <v-icon>mdi-chevron-down</v-icon>
          </button>
        </section>
        <div class="more-info">
          <video-content :video-src="helpVideo9"></video-content>
        </div>
      </div>

      <div v-if="showMenuTabContent === 'establishRelationshipObjectives'" class="doc-section--outer">
        <section class="doc-info-section doc-section bg-color-tertiary" id="establishRelationshipObjectives">
          <v-container fluid>
            <v-row>
              <v-col cols="12" class="mx-auto">
                <div class="doc-info__title-box mb-8 mb-md-6">
                  <h3 class="doc-info-title">Establish Relationship for Objectives</h3>
                  <p class="subtitle with-icon mt-2">
                    <img class="icon" width="24" alt="bulb" :src="bulbIcon"/>
                    Cascade: An Objective-to-Objective relation that shows how Objectives are related between different organisation levels. It is a <span class="info-primary">top-down approach</span> and established between 2 levels at a time.</p>
                </div>
              </v-col>
            </v-row>
            <v-row class="info-cards justify-center">
              <v-col cols="12" md="4" sm="6">
                <div class="info-card">
                  <div class="info-card--inner">
                    <div class="card-header">
                      <h5 class="card-title">Manually adding a relationship</h5>
                    </div>
                    <div class="card-body">
                      <ol>
                        <li>Check the 'Add Relation with another Objective' checkbox</li>
                        <li>Complete Related Team and Related With fields</li>
                      </ol>
                      <p class="info-primary mt-2">(Relation Type input field will be auto populated with Cascade)</p>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="4" sm="6">
                <div class="info-card">
                  <div class="info-card--inner">
                    <div class="card-header">
                      <h5 class="card-title">AI Recommendation to add a relationship</h5>
                    </div>
                    <div class="card-body">
                      <ol>
                        <li>Check the 'Add Relation with another Objective' checkbox</li>
                        <li>Make sure your Objective title has been entered</li>
                        <li>Click on the AI Recommendation button</li>
                        <li>Click accept next to the recommended objective of choice</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <button type="button" class="scroll-down-link" @click.stop="scrollToNextBlock">
            Scroll to watch this feature video <v-icon>mdi-chevron-down</v-icon>
          </button>
        </section>
        <div class="more-info">
          <video-content :video-src="helpVideo11"></video-content>
        </div>
      </div>

      <div v-if="showMenuTabContent === 'createKeyResult'" class="doc-section--outer">
        <section class="doc-info-section doc-section bg-color-tertiary" id="createKeyResult">
          <v-container fluid>
            <v-row>
              <v-col cols="12" class="mx-auto">
                <div class="doc-info__title-box mb-8 mb-md-6">
                  <h3 class="doc-info-title">Create a Key Result</h3>
                  <p class="subtitle with-icon mt-2">
                    <img class="icon" width="24" alt="bulb" :src="bulbIcon"/>
                    Key Results in the OKR (Objectives and Key Results) framework are specific, measurable outcomes that, when achieved, will indicate the successful completion of the Objective. Key Results are usually quantifiable and should have a clear target value or milestone.
                  </p>
                </div>
              </v-col>
            </v-row>
            <v-row class="info-cards justify-center">
              <v-col cols="12" md="4" sm="6">
                <div class="info-card">
                  <div class="info-card--inner">
                    <div class="card-header">
                      <h5 class="card-title">Entry Points</h5>
                    </div>
                    <div class="card-body">
                      <ol>
                        <li>Create Objective page: Click on Save & Add Key Result at the bottom of the page</li>
                        <li>List View: Click on the Add Key Result under the 3 dots next to an Objective</li>
                        <li>Objective Details page: Click on Add Key Result button in the Key Results section</li>
                        <li>Objective Details page:  Click on Add Key Result under the 3 dots</li>
                      </ol>
                      <p class="info-primary mt-2 font-italic"> (You can only see the below options if you are the owner of the Objective or OKR Champion or have been given admin rights)</p>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="4" sm="6">
                <div class="info-card">
                  <div class="info-card--inner">
                    <div class="card-header">
                      <h5 class="card-title">Steps for Creating a Key Result</h5>
                    </div>
                    <div class="card-body">
                      <ol>
                        <li>Land on the Create Key Result page through the entry points stated on the left side</li>
                        <li>Complete all mandatory fields</li>
                        <li>Click on Save & Review (for publishing the OKRs) or  Click on Save as Draft (to publish OKRs later)</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <button type="button" class="scroll-down-link" @click.stop="scrollToNextBlock">
            Scroll to watch this feature video <v-icon>mdi-chevron-down</v-icon>
          </button>
        </section>
        <div class="more-info">
          <video-content :video-src="helpVideo13"></video-content>
        </div>
      </div>

      <div v-if="showMenuTabContent === 'establishRelationshipKeyResults'" class="doc-section--outer">
        <section  class="doc-info-section doc-section bg-color-tertiary" id="establishRelationshipKeyResults">
          <v-container fluid>
            <v-row>
              <v-col cols="12" class="mx-auto">
                <div class="doc-info__title-box mb-8 mb-md-6">
                  <h3 class="doc-info-title">Establish Relationship for Key Result (1/2)</h3>
                  <p class="subtitle mt-2">When establishing a relationship for a key result, you will have 4 options: Mirrored, Linked, Split and Dependency.</p>
                </div>
              </v-col>
            </v-row>
            <v-row class="info-cards justify-center">
              <v-col cols="12" md="3" sm="6">
                <div class="info-card">
                  <div class="info-card--inner">
                    <div class="card-header">
                      <h5 class="card-title with-icon font-600"><img class="icon" :src="mirrorIcon" alt="mirror" />Mirrored</h5>
                    </div>
                    <div class="card-body">
                      <p>A Key Result to Key Result relation that shows how a KR is mirrored from upper to lower level and, the team that mirrors the Key Result drives and updates the Key result and the same updates automatically reflects on the Parent’s KR one level up.</p>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="3" sm="6">
                <div class="info-card">
                  <div class="info-card--inner">
                    <div class="card-header">
                      <h5 class="card-title with-icon font-600"><img class="icon" :src="linkedIcon" alt="linked" />Linked</h5>
                    </div>
                    <div class="card-body">
                      <p>A Key Result to Key Result relation that shows how KRs are linked between different teams / departments contributing towards a common goal. Each KR will be owned and accounted by the individual team.</p>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="3" sm="6">
                <div class="info-card">
                  <div class="info-card--inner">
                    <div class="card-header">
                      <h5 class="card-title with-icon font-600"><img class="icon" :src="splitIcon" alt="split"/>Split</h5>
                    </div>
                    <div class="card-body">
                      <p>A Key Result to Key Result relation that is a singular high-level KR being divided into several smaller KRs. These smaller KRs are distributed among different teams, each taking responsibility for a portion of delivering a bigger KR value which gets aggregated. </p>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="3" sm="6">
                <div class="info-card">
                  <div class="info-card--inner">
                    <div class="card-header">
                      <h5 class="card-title with-icon font-600"><img class="icon" :src="dependencyIcon" alt="dependency" />Dependency</h5>
                    </div>
                    <div class="card-body">
                      <p>A Key Result to Key Result relation that shows the dependency of one Key Result's achievement on the completion or progress of another Key Result. In this relation, one KR acts as a prerequisite or enabler for the achievement of another KR.</p>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>    
        </section>
        <div>
          <section class="doc-info-section doc-section bg-color-tertiary">
            <v-container fluid>
              <v-row>
                <v-col cols="12" class="mx-auto">
                  <div class="doc-info__title-box mb-8 mb-md-6">
                    <h3 class="doc-info-title">Establish Relationship for Key Result (2/2)</h3>
                  </div>
                </v-col>
              </v-row>
              <v-row class="info-cards justify-center">
                <v-col cols="12" md="4" sm="6">
                  <div class="info-card">
                    <div class="info-card--inner">
                      <div class="card-header">
                        <h5 class="card-title">Manually adding a relationship</h5>
                      </div>
                      <div class="card-body">
                        <ol>
                          <li>Check the 'Add Relation with another Key Result' checkbox</li>
                          <li>Complete Relation Type field</li>
                          <li>Complete Related Team & Relation Type fields</li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="4" sm="6">
                  <div class="info-card">
                    <div class="info-card--inner">
                      <div class="card-header">
                        <h5 class="card-title">Using AI Recommendation to Add a Relationship</h5>
                      </div>
                      <div class="card-body">
                        <ol>
                          <li>Check the 'Add Relation with another Key Result' checkbox</li>
                          <li>Make sure your Key Result title has been entered</li>
                          <li>Click on the AI Recommendation button</li>
                          <li>Click accept next to the recommended Key Result of choice</li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </section>
          <button type="button" class="scroll-down-link" @click.stop="scrollToNextBlock">
            Scroll to watch this feature video <v-icon>mdi-chevron-down</v-icon>
          </button>
          <div class="more-info">
            <video-content :video-src="helpVideo16"></video-content>
          </div>
        </div>
      </div>

      <div v-if="showMenuTabContent === 'addInitiativesUnderKeyResult'" class="doc-section--outer">
        <section class="doc-info-section doc-section bg-color-tertiary" id="addInitiativesUnderKeyResult">
          <v-container fluid>
            <v-row>
              <v-col cols="12" class="mx-auto">
                <div class="doc-info__title-box mb-8 mb-md-6">
                  <h3 class="doc-info-title">Add Initiatives for a Key Result</h3>
                  <p class="subtitle with-icon mt-2">
                    <img class="icon" width="24" alt="bulb" :src="bulbIcon"/>
                    Initiatives refer to a specific task or action taken by a team or individual to achieve a particular Key Result. Initiatives are the concrete steps or strategies that are planned and executed to make progress toward fulfilling a Key Result.
                  </p>
                </div>
              </v-col>
            </v-row>
            <v-row class="info-cards justify-center">
              <v-col cols="12" md="4" sm="6">
                <div class="info-card">
                  <div class="info-card--inner">
                    <div class="card-header">
                      <h5 class="card-title">Create an Initiative</h5>
                    </div>
                    <div class="card-body">
                      <ol>
                        <li>Click on <strong>Add Initiative</strong>  at the bottom of the Create/Edit Key Result page</li>
                        <li>Click on <strong>Add Initiative</strong> in the Initiatives section of the <strong>Key Result Details</strong> page of choice</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="4" sm="6">
                <div class="info-card">
                  <div class="info-card--inner">
                    <div class="card-header">
                      <h5 class="card-title">Steps</h5>
                    </div>
                    <div class="card-body">
                      <ol>
                        <li>Land  on the <strong>Create/Edit Key Result</strong> page</li>
                        <li>Scroll down to the bottom of the page and click on Add Initiative</li>
                        <li>Complete all or mandatory fields</li>
                        <li>Optionally you can duplicate an initiative by clicking on the Duplicate icon</li>
                        <li>Click on <strong>Save & Review</strong> (when on the Create Key Result page) or Click on Save (when on the Edit Key Result page)</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <button type="button" class="scroll-down-link" @click.stop="scrollToNextBlock">
            Scroll to watch this feature video <v-icon>mdi-chevron-down</v-icon>
          </button>
        </section>
        <div class="more-info">
          <video-content :video-src="helpVideo18"></video-content>
        </div>
      </div>

      <div v-if="showMenuTabContent === 'reviewPublishOKRs'" class="doc-section--outer">
        <section class="doc-info-section doc-section bg-color-tertiary" id="reviewPublishOKRs">
          <v-container fluid>
            <v-row>
              <v-col cols="12" class="mx-auto">
                <div class="doc-info__title-box mb-8 mb-md-6">
                  <h3 class="doc-info-title">Review & Publish OKRs</h3>
                  <p class="subtitle with-icon mt-2">
                    <img class="icon" width="24" alt="bulb" :src="bulbIcon"/>
                    After creating an Objective and minimum 1 Key Result, you will get an opportunity to review your OKRs before publishing and making them visible organisation wide.
                  </p>
                </div>
              </v-col>
            </v-row>
            <v-row class="info-cards">
              <v-col cols="12" md="8" sm="10">
                <div class="info-card">
                  <div class="info-card--inner">
                    <div class="card-header">
                      <h5 class="card-title">Steps</h5>
                    </div>
                    <div class="card-body">
                      <ol>
                        <li>Once you land on the OKR Summary Page, you will see a pencil icon appearing under Objective and each Key Result</li>
                        <li>Click on the pencil icon that you wish to apply changes to</li>
                        <li>Update the required mandatory fields on the open form</li>
                        <li>Click on Save and you will return to the OKR Summary page</li>
                        <li>Review the changes applied and then click on Save & Publish (to publish the OKRs immediately) or Click on Save as Draft (to publish your OKRs later)</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <button type="button" class="scroll-down-link" @click.stop="scrollToNextBlock">
            Scroll to watch this feature video <v-icon>mdi-chevron-down</v-icon>
          </button>
        </section>
        <div class="more-info">
          <video-content :video-src="helpVideo20"></video-content>
        </div>
      </div>

      <div v-if="showMenuTabContent === 'manageYourOKRs'">
        <section class="doc-intro-section doc-section bg-color-primary" id="manageYourOKRs">
          <v-container fluid>
            <LogoBox :color-light="true"/>
            <v-row class="align-center h-100">
              <v-col>
                <h2 class="doc-intro-title doc-section-title" data-count="0.3">Manage Your OKRs</h2>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </div>

      <div v-if="showMenuTabContent === 'updateKeyResults'" class="doc-section--outer">
        <section class="doc-info-section doc-section bg-color-tertiary" id="updateKeyResults">
          <v-container fluid>
            <v-row>
              <v-col cols="12" class="mx-auto">
                <div class="doc-info__title-box mb-8 mb-md-6">
                  <h3 class="doc-info-title">Update Key Results</h3>
                  <p class="subtitle with-icon mt-2">
                    <img class="icon" width="24" alt="bulb" :src="bulbIcon"/>
                    Key Results will be updated according to the new total value input. <span class="info-primary">E.g. If your previous value was 5% and your new progress is 8%, 
                      you should enter 8% as the updated total value to accurately reflect the progress.</span> You can also update a Key Result for a past date withing the OKR 
                      cycle by using the date option in the top right corner.
                  </p>
                </div>
              </v-col>
            </v-row>
            <v-row class="info-cards">
              <v-col cols="12" md="5">
                <div class="info-card">
                  <div class="info-card--inner">
                    <div class="card-header">
                      <h5 class="card-title">
                        Entry Points <span class="info-primary">(You can only see the below option if you are the owner of the Key Result or an OKR Champion have been given admin rights)</span>
                      </h5>
                    </div>
                    <div class="card-body">
                      <ol>
                        <li>List View: Click on Update under Actions column next to a Key Result you own</li>
                        <li>List view: Click on Update under the 3 dots you own</li>
                        <li>Key Results Details page: Click on Update button on top right side of page</li>
                        <li>Key Results Details page: Click on Update under the 3 dots</li>
                        <li>Objective Details page: Scroll down to the Key Results section and click on Update under Actions column next to a Key Result you own</li>
                        <li>Objective Details page:  Scroll down to the Key Results section and click on Update under the 3 dots next to a Key Result you own</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="7">
                <ul class="update-key-steps">
                  <li class="update-key-step mr-16">
                    <h6 class="title">Step 1</h6>
                    <p class="desc">Land on the <strong>Update Key Result</strong> page through the entry points stated above</p>
                  </li>
                  <li class="update-key-step mr-12">
                    <h6 class="title">Step 2</h6>
                    <p class="desc">Fill in New Value on the <strong>New Total Value</strong> field</p>
                  </li>
                  <li class="update-key-step mr-8">
                    <h6 class="title">Step 3</h6>
                    <p class="desc">Choose your confidence level (Confidence level indicates the certainty that reported progress accurately reflects Key Result achievement status.)</p>
                  </li>
                  <li class="update-key-step mr-4">
                    <h6 class="title">Step 4</h6>
                    <p class="desc">Optionally you can add a comment below</p>
                  </li>
                  <li class="update-key-step">
                    <h6 class="title">Step 5</h6>
                    <p class="desc">Click on <strong>Update</strong> (to update the Key Result immediately)</p>
                  </li>
                </ul>
              </v-col>
            </v-row>
          </v-container>
          <button type="button" class="scroll-down-link" @click.stop="scrollToNextBlock">
            Scroll to watch this feature video <v-icon>mdi-chevron-down</v-icon>
          </button>
        </section>
        <div class="more-info">
          <video-content :video-src="helpVideo23"></video-content>
        </div>
      </div>

      <div v-if="showMenuTabContent === 'viewMyOKRs'" class="doc-section--outer">
        <section class="doc-info-section doc-section bg-color-tertiary" id="viewMyOKRs">
          <v-container fluid>
            <v-row>
              <v-col cols="12" class="mx-auto">
                <div class="doc-info__title-box mb-8 mb-md-6">
                  <h3 class="doc-info-title">View My OKRs</h3>
                  <p class="subtitle with-icon mt-2">
                    <img class="icon" width="24" alt="bulb" :src="bulbIcon"/>
                    My OKRs list view allows you to see all the key results you own, even if you do not own the corresponding objective. This is also the only page where you will be able to see your saved <span class="info-primary">OKR drafts / unpublished OKRs.</span>
                  </p>
                </div>
              </v-col>
            </v-row>
            <v-row class="info-cards">
              <v-col cols="12" md="4" sm="6">
                <div class="info-card">
                  <div class="info-card--inner">
                    <div class="card-header">
                      <h5 class="card-title">Entry Point to My OKRs Tab</h5>
                    </div>
                    <div class="card-body">
                      <ol>
                        <li>Click on OKRs on the side navigation</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="4" sm="6">
                <div class="info-card">
                  <div class="info-card--inner">
                    <div class="card-header">
                      <h5 class="card-title">Actions (under the 3 dots) on My OKRs tab</h5>
                    </div>
                    <div class="card-body">
                      <ol>
                        <li>Objectives you own – Add Key Result, Edit & Close</li>
                        <li>Other's Objectives – View Details, Comment</li>
                        <li>Key Result's you own – Update & Edit</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="4" sm="6">
                <div class="info-card">
                  <div class="info-card--inner">
                    <div class="card-header">
                      <h5 class="card-title">Primary Actions on My OKRs tab</h5>
                    </div>
                    <div class="card-body">
                      <ol>
                        <li>Other's Objectives – Comment</li>
                        <li>Key Result's you own – Update</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <button type="button" class="scroll-down-link" @click.stop="scrollToNextBlock">
            Scroll to watch this feature video <v-icon>mdi-chevron-down</v-icon>
          </button>
        </section>
        <div class="more-info">
          <video-content :video-src="helpVideo25"></video-content>
        </div>
      </div>

      <div v-if="showMenuTabContent === 'viewMyTeamOKRs'" class="doc-section--outer">
        <section class="doc-info-section doc-section bg-color-tertiary" id="viewMyTeamOKRs">
          <v-container fluid>
            <v-row>
              <v-col cols="12" class="mx-auto">
                <div class="doc-info__title-box mb-8 mb-md-6">
                  <h3 class="doc-info-title">View My Team OKRs</h3>
                  <p class="subtitle with-icon mt-2">
                    <img class="icon" width="24" alt="bulb" :src="bulbIcon"/>
                    My Team OKRs list view allows you to see the full set of OKRs of the teams that you are part of as a manager or as a team member. If you are in multiple teams, you will have the option of toggling your view to view one team's OKR sets at a time.
                  </p>
                </div>
              </v-col>
            </v-row>
            <v-row class="info-cards justify-center">
              <v-col cols="12" md="4" sm="6">
                <div class="info-card">
                  <div class="info-card--inner">
                    <div class="card-header">
                      <h5 class="card-title">Entry Point to My Team OKRs Tab</h5>
                    </div>
                    <div class="card-body">
                      <ol>
                        <li>Click on OKRs on side navigation</li>
                        <li>Click on My Team OKRs tab</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="4" sm="6">
                <div class="info-card">
                  <div class="info-card--inner">
                    <div class="card-header">
                      <h5 class="card-title">Actions (under the 3 dots) on My  Team OKRs tab</h5>
                    </div>
                    <div class="card-body">
                      <ol>
                        <li>Objectives you own – Add Key Result, Edit and Close</li>
                        <li>Other's Objectives – View Details, Comment</li>
                        <li>Key Result's you own – Update and Edit</li>
                        <li>Other's Key Results – View Details, Comment</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="4" sm="6">
                <div class="info-card">
                  <div class="info-card--inner">
                    <div class="card-header">
                      <h5 class="card-title">Primary Actions (appearing next to OKRs) on My Team OKRs tab</h5>
                    </div>
                    <div class="card-body">
                      <ol>
                        <li>Other's Objectives – Comment</li>
                        <li>Key Result's you own – Update</li>
                        <li>Other's Key Results – Comment</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <button type="button" class="scroll-down-link" @click.stop="scrollToNextBlock">
            Scroll to watch this feature video <v-icon>mdi-chevron-down</v-icon>
          </button>
        </section>
        <div class="more-info">
          <video-content :video-src="helpVideo27"></video-content>
        </div>
      </div>

      <div v-if="showMenuTabContent === 'viewOrganisationOKRs'" class="doc-section--outer">
        <section class="doc-info-section doc-section bg-color-tertiary" id="viewOrganisationOKRs">
          <v-container fluid>
            <v-row>
              <v-col cols="12" class="mx-auto">
                <div class="doc-info__title-box mb-8 mb-md-6">
                  <h3 class="doc-info-title">View My Organisation OKRs</h3>
                  <p class="subtitle with-icon mt-2">
                    <img class="icon" width="24" alt="bulb" :src="bulbIcon"/>
                    Organisation OKRs list view allows you to see all published OKR sets in your organisation. <br>
                    The OKRs are split into different levels: Organisation, Department, Sub-Department and Team, depending on how your organisation is set up.
                  </p>
                </div>
              </v-col>
            </v-row>
            <v-row class="info-cards justify-center">
              <v-col cols="12" md="4" sm="6">
                <div class="info-card">
                  <div class="info-card--inner">
                    <div class="card-header">
                      <h5 class="card-title">Entry Point to Organisation OKRs Tab</h5>
                    </div>
                    <div class="card-body">
                      <ol>
                        <li>Click on OKRs on the side navigation</li>
                        <li>Click on Organisation OKRs tab</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="4" sm="6">
                <div class="info-card">
                  <div class="info-card--inner">
                    <div class="card-header">
                      <h5 class="card-title">Actions (under the 3 dots) on Organisation OKRs tab</h5>
                    </div>
                    <div class="card-body">
                      <ol>
                        <li>Objectives you own – Add Key Result, Edit and Close</li>
                        <li>Other's Objectives – View Details, Comment</li>
                        <li>Key Result's you own – Update and Edit</li>
                        <li>Other's Key Results – View Details, Comment</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="4" sm="6">
                <div class="info-card">
                  <div class="info-card--inner">
                    <div class="card-header">
                      <h5 class="card-title">Actions (under the Actions column) on Organisation OKRs tab</h5>
                    </div>
                    <div class="card-body">
                      <ol>
                        <li>Other's Objectives – Comment</li>
                        <li>Key Result's you own – Update</li>
                        <li>Other's Key Results – Comment</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <button type="button" class="scroll-down-link" @click.stop="scrollToNextBlock">
            Scroll to watch this feature video <v-icon>mdi-chevron-down</v-icon>
          </button>
        </section>
        <div class="more-info">
          <video-content :video-src="helpVideo29"></video-content>
        </div>
      </div>

      <div v-if="showMenuTabContent === 'alignmentView'" class="doc-section--outer">
        <section class="doc-info-section doc-section bg-color-tertiary" id="alignmentView">
          <v-container fluid>
            <v-row>
              <v-col cols="12" class="mx-auto">
                <div class="doc-info__title-box mb-8 mb-md-6">
                  <h3 class="doc-info-title">Alignment Across All OKRs</h3>
                  <p class="subtitle with-icon mt-2">
                    <img class="icon" width="24" alt="bulb" :src="bulbIcon"/>
                    An OKR Alignment page helps ensure that goals at all levels (individual, team, department, and organisational) are aligned and support each other. It provides a clear view of how individual and team objectives contribute to broader organisational goals.
                  </p>
                </div>
              </v-col>
            </v-row>
            <v-row class="info-cards justify-center">
              <v-col cols="12" md="5" sm="6">
                <div class="info-card">
                  <div class="info-card--inner">
                    <div class="card-header">
                      <h5 class="card-title">Entry Point to Alignment Page</h5>
                    </div>
                    <div class="card-body">
                      <ol>
                        <li>Click on OKRs on the side navigation</li>
                        <li>Click on the Alignment tab</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="5" sm="6">
                <div class="info-card">
                  <div class="info-card--inner">
                    <div class="card-header">
                      <h5 class="card-title">How to navigate:</h5>
                    </div>
                    <div class="card-body">
                      <ul>
                        <li>Drag the screen with your mouse to navigate through the screen</li>
                        <li>My Team OKRs button to highlight OKR sets for your team</li>
                        <li>Click on Objective Display Cards to see full OKR set</li>
                        <li>Relationship clickable icon <img class="icon" alt="top-click" :src="topClick" style="height: 100%; width: auto"/> available to click to see which OKRs are related with other OKRs</li>
                        <li>Full Screen Mode icon</li>
                        <li>Zoom In/Out feature</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <button type="button" class="scroll-down-link" @click.stop="scrollToNextBlock">
            Scroll to watch this feature video <v-icon>mdi-chevron-down</v-icon>
          </button>
        </section>
        <div class="more-info">
          <video-content :video-src="helpVideo31"></video-content>
        </div>
      </div>

<!--      <section class="doc-section bg-color-primary">-->
<!--        <v-container fluid>-->
<!--          <LogoBox :color-light="true"/>-->
<!--          <v-row class="h-100">-->
<!--            <v-col cols="12 mt-auto">-->
<!--              <h2 class="thank-you-title text-center">Thank You</h2>-->
<!--            </v-col>-->
<!--            <v-col cols="12" class="mt-auto">-->
<!--              <p class="address-info align-self-end">Office 103, Building 1, Dubai Internet City, Dubai, United Arab Emirates</p>-->
<!--            </v-col>-->
<!--          </v-row>-->
<!--        </v-container>-->
<!--      </section>-->

      <FooterSite />
    </div>
  </div>
</template>

<script>
import {eventBus} from "@/event-bus";
import { videoMixin } from '@/plugins/videoMixin';
import HeaderSite from "@/components/Header";
import FooterSite from "@/components/Footer";
import LogoBox from "@/components/LogoBox";
import VideoContent from "@/components/VideoContent";
import HelpCenterMenu from "@/components/HelpCenterMenu";

export default {
  name: "Help",
  mixins: [videoMixin],
  components: {
    HelpCenterMenu,
    VideoContent,
    HeaderSite,
    FooterSite,
    LogoBox
  },
  data() {
    return {
      siteLogo: require("@/assets/img/logo-white-text.svg"),
      siteLogoPrimary: require("@/assets/img/logo-primary-text.svg"),
      strategyPyramid: require("@/assets/img/help-page/strategy-pyramid.png"),
      bulbIcon: require("@/assets/img/icons/icon-bulb.png"),
      objectivesIcon: require("@/assets/img/icons/icon-objective.png"),
      keyResultsIcon: require("@/assets/img/icons/icon-key-results.png"),
      mirrorIcon: require("@/assets/img/icons/icon-mirror.svg"),
      linkedIcon: require("@/assets/img/icons/icon-linked.png"),
      splitIcon: require("@/assets/img/icons/icon-split.png"),
      topClick: require("@/assets/img/icons/top-click.png"),
      dependencyIcon: require("@/assets/img/icons/icon-dependency.png"),
      pageBanner: require("@/assets/img/help-page/page-banner.png"),

      //Video
      helpVideo9: require("@/assets/video/help/m-9.mp4"),
      helpVideo11: require("@/assets/video/help/m-11.mp4"),
      helpVideo13: require("@/assets/video/help/m-13.mp4"),
      helpVideo16: require("@/assets/video/help/m-16.mp4"),
      helpVideo18: require("@/assets/video/help/m-18.mp4"),
      helpVideo20: require("@/assets/video/help/m-20.mp4"),
      helpVideo23: require("@/assets/video/help/m-23.mp4"),
      helpVideo25: require("@/assets/video/help/m-25.mp4"),
      helpVideo27: require("@/assets/video/help/m-27.mp4"),
      helpVideo29: require("@/assets/video/help/m-29.mp4"),
      helpVideo31: require("@/assets/video/help/m-31.mp4"),

      //menu
      showMenuTabContent: '',
    };
  },
  beforeMount() {
    this.showMenuTabContent = this.getSelectedTab
  },
  methods: {
    scrollToNextBlock() {
      const docSectionOuter = document.querySelectorAll('.doc-section--outer');
      docSectionOuter.forEach(item => {
        const nextBlock = item.querySelector('.more-info');
        if (nextBlock) {
          nextBlock.scrollIntoView({ behavior: 'smooth' });
        }
      })
    },
    scrollUpActiveSection(val){
      let selectedSection = document.body?.querySelector(`${val}`)
      if(selectedSection) {
        let pageContent = selectedSection.closest('.page-main-content')
        pageContent.style.scrollBehavior = "smooth"
        pageContent.scrollTop =  0
      }
    }
  },
  created() {
    eventBus.value.$on('showMenuTabContent', (value) => {
      this.showMenuTabContent = value
      setTimeout(() => {
        this.scrollUpActiveSection(`#${value}`)
      })
    });
  },
  computed: {
    getSelectedTab(){
      if (window.location.hash) {
        this.showMenuTabContent = window.location.href.split('#')[1]
      } else {
        this.showMenuTabContent = 'trainingModule'
      }
      return this.showMenuTabContent
    },
  },
  head() {
    return {
      title: "Help",
      meta: [
        {
          hid: "description",
          name: "description",
          content:
              "OKR Training module",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.main-section {
  padding-top: 64px; //header height
}
.help-page {
  display: flex;
  height: 100vh;
  overflow: hidden;
  .page-main-content {
    flex: 1;
    overflow-y: auto;
  }
}
.font-600 {
  font-weight: 600;
}
.info-primary{
  color: #0442bf;
}
.bg-color-primary {
  background-color: #0442BF;
  color: #fff;
  .logo-box {
    color: #FFCA07;
  }
}
.bg-color-secondary {
  background-color: #94E0D4;
  color: #000;
  .logo-box {
    color: #0D1017;
  }
}
.bg-color-tertiary {
  background-color: #F3E3EB;
  color: #000;
  .logo-box {
    color: #1D1D1B;
  }
}
.page-banner {
  padding-top: 30px;
  padding-bottom: 30px;
  min-height: calc(100vh - 64px); //64px header height
  position: relative;
  .banner-img {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    max-width: none;
    z-index: 1;
  }
  .page-title {
    font-size: 40px;
    @media screen and (max-width: 768px){
      font-size: 32px;
    }
  }
  .copyright-text {
    font-family: 'Recoleta', sans-serif;
    font-size: 24px;
    color: #94E0D4;
    margin-bottom: 0;
    @media screen and (max-width: 768px){
      font-size: 20px;
    }
  }
  .page-banner__content {
    position: relative;
    z-index: 2;
  }
}
.scroll-down-link {
  position: absolute;
  right: 20px;
  bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #0442bf;
  transition: color .3s ease-in-out;
  @media screen and (max-width: 1024px) {
    display: none;
  }
  &:hover {
    color: #002e8a;
  }
  .v-icon {
    margin-left: 8px;
  }
}
.doc-section {
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;
  min-height: calc(100vh - 64px);
  display: flex;
  p {
    font-size: 16px;
  }
  @media screen and (max-width: 992px){
    padding: 24px 0;
  }
  .address-info {
    font-family: 'Campton Book', sans-serif;
    font-size: 18px;
    margin-bottom: 0;
  }
  .thank-you-title {
    font-size: 64px;
    @media screen and (max-width: 768px){
      font-size: 48px;
    }
  }
  .doc-section-title {
    font-weight: 500;
    line-height: 1.2;
    &[data-count] {
      display: flex;
      &::before {
        font-family: 'Recoleta', sans-serif;
        content: attr(data-count);
        height: 84px;
        width: 36px;
        flex-shrink: 0;
        border-radius: 40px;
        margin-right: 32px;
        font-size: 16px;
        letter-spacing: 0.04em;
        padding: 6px;
        color: currentColor;
        border: 4px solid transparent;
        display: inline-flex;
        align-items: flex-end;
        justify-content: center;
        transform: translateY(-1em);
        @media screen and (max-width: 768px){
          width: 28px;
          height: 64px;
          font-size: 14px;
        }
      }
    }
  }
  &.bg-color-secondary {
    .doc-section-title {
      &::before {
        border-color: #0442BF;
      }
    }
  }
  &.bg-color-primary {
    .doc-section-title {
      &::before {
        border-color: #FFCA07;
      }
    }
  }
}
.doc-menu-title {
  font-size: 74px;
  color: #0D0D0D;
  @media screen and (max-width: 768px){
    font-size: 56px;
  }
}
.doc-menu-list {
  counter-reset: info-menu;
  list-style-type: none;
  font-size: 16px;
  .anchor-link {
    color: #000;
    &:hover {
      color: #0442BF;
    }
  }
  > li {
    &:not(:first-child) {
      margin-top: 16px;
    }
    .menu-title {
      .anchor-link {
        font-family: 'Campton Book', sans-serif;
        display: inline-flex;
        margin-bottom: 16px;
        &::before {
          counter-increment: info-menu;
          content: counter(info-menu, decimal-leading-zero);
          margin-right: 16px;
          font-size: 16px;
        }
      }
    }
  }
  ul {
    padding-left: 30px;
    font-family: 'Calibri', sans-serif;
    font-weight: 300;
    li {
      list-style-type: none;
      line-height: 1.4;
      &::before {
        content: '';
        display: inline-block;
        width: 0.3em;
        height: 0.3em;
        border-radius: 50%;
        background-color: currentColor;
        transform: translateY(-0.1em);
        margin-right: 16px;
      }
    }
  }
}

.doc-intro-section {
  .doc-intro-title {
    font-size: 60px;
    font-weight: bold;
    @media screen and (max-width: 768px){
      font-size: 42px;
    }
  }
}

.doc-info-section {
  .doc-info__title-box {
    .doc-info-title {
      font-size: 35px;
      color: #000;
      font-weight: bold;
      @media screen and (max-width: 768px){
        font-size: 28px;
      }
    }
    .subtitle {
      margin-bottom: 0;
      font-size: 20px;
      &.with-icon {
        position: relative;
        padding-left: 40px;
        @media screen and (max-width: 768px){
          padding-left: 30px;
        }
        .icon {
          position: absolute;
          left: 0;
          width: 20px;
          height: auto;
          @media screen and (max-width: 768px){
            width: 16px;
          }
        }
      }
    }
  }
  .strategy-list {
    list-style-type: none;
    li {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
    .info {
      font-family: 'Campton', sans-serif;
      font-style: italic;
    }
    strong {
      color: #385723;
    }
  }
}
.info-list {
  li {
    list-style-type: none;
    line-height: 1.2;
    position: relative;
    padding-left: 18px;
    .label {
      font-family: 'Recoleta', sans-serif;
      color: #0442bf;
    }
    &::before {
      content: '';
      position: absolute;
      display: inline-block;
      width: 0.25em;
      height: 0.25em;
      border-radius: 50%;
      top: 0.5em;
      left: 0;
      margin-right: 10px;
      background-color: #0442bf;
    }
  }
}

.info-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  .info-card--inner {
    border-radius: 6px;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .card-header,
  .card-body {
    padding: 12px 16px;
  }
  .card-header {
    background-color: #B7DFBD;
    .info-primary {
      font-family: 'Recoleta', sans-serif;
      margin-bottom: 0;
    }
  }
  .card-body {
    font-weight: 300;
    background-color: #fff;
    flex: 1;
    font-size: 14px;
    p {
      font-size: 14px;
    }
    strong {
      font-weight: bold;
    }
    ol, ul {
      padding-left: 16px;
    }
  }
  .card-title {
    font-family: 'Campton', sans-serif;
    font-size: 16px;
    font-weight: 600;
    &.with-icon {
      position: relative;
      padding-left: 30px;
      .icon {
        max-height: 30px;
        height: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
  }
  .info-examples {
    font-size: 12px;
    .example-description {
      color: #0542BF;
    }
    p {
      font-size: 12px;
      margin-bottom: 0;
    }
  }
}
.info-card-steps {
  .info-card--inner {
    border-radius: 0;
  }
  .info-card {
    &:not(.info-card-right) {
      .card-body {
        padding-right: 50px;
        @media screen and (max-width: 601px){
          padding-right: 0;
        }
      }
    }
  }
  .info-card-center {
    margin-left: -50px;
    @media screen and (max-width: 601px){
      margin-left: 0;
    }
  }
  .info-card-center,
  .info-card-right {
    margin-top: -80px;
    width: 100%;
    @media screen and (max-width: 601px){
      margin-top: 0;
    }
  }
  .info-card-right {
    margin-left: -100px;
    @media screen and (max-width: 601px){
      margin-left: 0;
    }
  }
}
#strategyPyramid {
  .doc-info-title {
    font-size: 40px;
    position: absolute;
    right: 0;
    top: 20px;
    max-width: 20%;
    @media screen and (max-width: 768px){
      max-width: none;
      font-size: 32px;
      position: relative;
      top: auto;
      right: auto;
    }
  }
}
.update-key-steps {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .update-key-step {
    position: relative;
    display: flex;
    align-items: center;
    flex: 0 0 70%;
    max-width: 70%;
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 10px;
      &::after {
        content: '';
        position: absolute;
        background-image: url('@/assets/img/icons/step-arrow.png');
        background-position: center;
        background-size: cover;
        width: 42px;
        height: 42px;
        top: calc(100% - 20px);
        right: 6px;
        z-index: 1;
      }
    }
    .title {
      font-size: 14px;
      font-weight: normal;
      white-space: nowrap;
      flex-shrink: 0;
      margin-right: 12px;
    }
    .desc {
      font-size: 14px;
      color: #262626;
      margin-bottom: 0;
      padding: 6px 50px 6px 6px;
      background-color: #B7E0BE;
      min-height: 40px;
      width: 100%;
      border-radius: 6px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}
</style>
